import './FusionInZamoskvorechye.css'

import fusionCover from '../../../../assets/private/Квартира Замоскворечье/0.jpg'
import fusionGallery1 from '../../../../assets/private/Квартира Замоскворечье/1.jpg'
import fusionGallery2 from '../../../../assets/private/Квартира Замоскворечье/2.jpg' 
import fusionGallery3 from '../../../../assets/private/Квартира Замоскворечье/3.jpg'
import fusionGallery4 from '../../../../assets/private/Квартира Замоскворечье/4.jpg'
import fusionGallery5 from '../../../../assets/private/Квартира Замоскворечье/5.jpg'
import fusionGallery6 from '../../../../assets/private/Квартира Замоскворечье/6.jpg'

import fusionCoversmall from '../../../../assets/private/Квартира Замоскворечье/0s.jpg'
import fusionGallery1small from '../../../../assets/private/Квартира Замоскворечье/1s.jpg'
import fusionGallery2small from '../../../../assets/private/Квартира Замоскворечье/2s.jpg' 
import fusionGallery3small from '../../../../assets/private/Квартира Замоскворечье/3s.jpg'
import fusionGallery4small from '../../../../assets/private/Квартира Замоскворечье/4s.jpg'
import fusionGallery5small from '../../../../assets/private/Квартира Замоскворечье/5s.jpg'
import fusionGallery6small from '../../../../assets/private/Квартира Замоскворечье/6s.jpg'


import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LazyImage from '../../../../components/LazyImage/LazyImage'
import useScrollTop from '../../../../hooks/useScrollTop'
import { useMediaQuery } from 'usehooks-ts'
import BackBtn from '../../../../components/BackBtn/BackBtn'

function FusionInZamoskvorechye() {
    const mobile = useMediaQuery('(max-width: 480px)')
    useScrollTop()
    const { t } = useTranslation();
    const images = [fusionCover, fusionGallery1,fusionGallery2,fusionGallery3,fusionGallery4,fusionGallery5,fusionGallery6]
    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleFusionImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }
    
    const closeSlider = () => {
            setSliderVisible(false)
    }

    return ( 
        <section id='Fusion'>
            <BackBtn title={'private'} desktopRoute={['projects','private','Fusion in Zamoskvorechye']} destination={'/private'}/>
            <div className="project-row project-gap-50" >
                <div className="row-left">
                    <div className="project-title-container">
                        <span className="project-title">{t("Fusion in Zamoskvorechye")}</span>
                        <span className="project-city">{t("Moscow")}</span>
                        <span className="project-year"> 2021 </span>
                    </div>
                    <p className="project-description-text">
                       {t("fusion-decription-text")}
                    </p>
                </div>
                <div className="row-right">
                    <LazyImage 
                        image={fusionCover} 
                        placeholder={fusionCoversmall} 
                        class={'project-img'} 
                        aspect={1.46} 
                        callback={() => handleFusionImageClick(0)}
                    />
                </div>
            </div>
            <div className="project-row wr-center project-gap-10" style={mobile?{marginTop:20}:{marginTop:100}}>
                <div className="row-left">
                    <div className="project-double">
                        <LazyImage 
                            image={fusionGallery1} 
                            placeholder={fusionGallery1small} 
                            class={'project-img'} 
                            aspect={0.828} 
                            callback={() => handleFusionImageClick(1)}
                        />
                        <LazyImage 
                            image={fusionGallery2} 
                            placeholder={fusionGallery2small} 
                            class={'project-img'} 
                            aspect={0.828} 
                            callback={() => handleFusionImageClick(2)}
                        />
                    </div>
                </div>
                <div className="row-right">
                    <LazyImage 
                        image={fusionGallery3} 
                        placeholder={fusionGallery3small} 
                        class={'project-img'} 
                        aspect={0.826} 
                        callback={() => handleFusionImageClick(3)}
                    />
                </div>
            </div>
            <div className="project-row wr-end project-gap-10" style={mobile?{marginTop:50}:{marginTop:100}}>
                <div className="row-left">
                    <LazyImage 
                        image={fusionGallery4} 
                        placeholder={fusionGallery4small} 
                        class={'project-img'} 
                        aspect={1.26} 
                        callback={() => handleFusionImageClick(4)}
                    />
                </div>
                <div className="row-right">
                    <div className="project-double">
                        <LazyImage 
                            image={fusionGallery6} 
                            placeholder={fusionGallery6small} 
                            class={'project-img'} 
                            aspect={1.26} 
                            callback={() => handleFusionImageClick(6)}
                        />
                        <LazyImage 
                            image={fusionGallery5} 
                            placeholder={fusionGallery5small} 
                            class={'project-img'} 
                            aspect={1.26} 
                            callback={() => handleFusionImageClick(5)}
                        />
                    </div>
                </div>
            </div>

            {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></> }
        </section> 
    );
}

export default FusionInZamoskvorechye;