import './TermsPage.css'
import { useTranslation } from 'react-i18next';
import termRu from '../../locales/terms-ru';
import termsEn from '../../locales/terms-en';

function TermsPage() {
    const { t, i18n } = useTranslation()

    return ( 
        <section id="Terms">
            <h1 className='terms-title'>{t("terms")}</h1>
            {i18n.language === 'ru' ? termRu() : termsEn()}
        </section>);
}

export default TermsPage;