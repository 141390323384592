import { Outlet } from "react-router-dom";
import { Fragment } from "react";
import Header from "../Header/Header";
import TopAnchor from "../TopAnchor/TopAnchor";
import Popup from "../Popup/Popup";


function PageLayout(props) {
    return ( 
        <Fragment>
            <Header/>
            <Outlet/>
            {props.anchor? <TopAnchor/> : <></>}
            <Popup x={0} y={0}/>
        </Fragment>
    );
}

export default PageLayout;