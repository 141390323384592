import './Cafe.css'
import BackBtn from '../../../../components/BackBtn/BackBtn'
import useScrollTop from '../../../../hooks/useScrollTop'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'
import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider'

import cafe1 from '../../../../assets/public/Кафе/1.webp'
import cafe2 from '../../../../assets/public/Кафе/2.webp'
import cafe3 from '../../../../assets/public/Кафе/3.webp'
import cafe4 from '../../../../assets/public/Кафе/4.webp'
import cafe5 from '../../../../assets/public/Кафе/5.webp'
import cafe6 from '../../../../assets/public/Кафе/6.webp'
import cafe7 from '../../../../assets/public/Кафе/7.webp'

function Cafe() {
    const mobile = useMediaQuery('(max-width:480px)')
    useScrollTop()
    const { t } = useTranslation();
    const images = [cafe1, cafe2, cafe3, cafe4, cafe5, cafe6, cafe7]

    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleCafeImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }

    const closeSlider = () => {
        setSliderVisible(false)
    }


    return ( 
    <section id='Cafe' className='project-container'>
        <BackBtn title={'public'} desktopRoute={['projects','public','Vintage Coffee Shop']} destination={'/public'}/>
        <div className="project-row project-gap-50">
            <div className="row-left">
                <div className="project-title-container h-mb-1-title">
                    <span className="project-title">{t("Vintage Coffee Shop")}</span>
                    <span className="project-city">{t("Saint-Petersburg")}</span>
                    <span className="project-year"> 2024 </span>
                </div>
                <p className="project-description-text" style={{width:'97%'}}>
                    {t("coffee-description-text")}
                    <br/>
                    <br/>
                    {t("coffee-description-text-extra")}
                </p>
            </div>
            <div className="row-right">
                <img src={cafe1} className='project-img' alt="" onClick={() => handleCafeImageClick(0)}/>
            </div>
        </div>
        <div className="project-row project-gap-50" style={mobile? {marginTop:10}:{marginTop:70}}>
            <div className="row-left">
                <img src={cafe2} className='project-img' alt="" onClick={() => handleCafeImageClick(1)}/>
            </div>
            <div className="row-right">
                <p className="project-description-text" style={{margin:0, width:'98%'}}>
                    {t("coffee-description-text-2")}
                </p>
            </div>
        </div>
        <div className="project-row project-gap-50 c-g-10" style={mobile? {marginTop:50}:{marginTop:20}}>
            <div className="row-left">
                <div className="project-double c-row-d">
                    <img src={cafe3} className='project-img c-s-img' alt="" onClick={() => handleCafeImageClick(2)}/>
                </div>
            </div>
            <div className="row-right">
                <div className="project-double c-row-d">
                    <img src={cafe4} className='project-img c-s-img c-s-img-l' alt="" onClick={() => handleCafeImageClick(3)}/>
                    {mobile? <img src={cafe5} className='project-img c-s-img' alt="" onClick={() => handleCafeImageClick(4)}/>:<></> }
                </div>
            </div>
        </div>
        <div className="project-row project-gap-50 c-g-10 c-g-0 c-m-tr" style={mobile? {marginTop:10}:{marginTop:20}}>
            <div className="row-left">
                <div className="project-double c-row-d">
                {!mobile? <img src={cafe5} className='project-img c-s-img c-s-img-l' alt="" onClick={() => handleCafeImageClick(4)}/>:<></> }
                </div>
            </div>
            <div className="row-right">
                <div className="project-double c-row-d">
                    <img src={cafe6} className='project-img c-s-img c-s-img-l' alt="" onClick={() => handleCafeImageClick(5)}/>
                </div>
            </div>
        </div>
        <div className="project-row project-gap-50 c-g-10" style={mobile? {marginTop:0}:{marginTop:50}}>
            <div className="row-left">
            </div>
            <div className="row-right">
                <div className="project-double c-row-d">
                    <img src={cafe7} className={mobile?'project-img c-s-img c-s-img-l':'project-img c-s-img'} alt="" onClick={() => handleCafeImageClick(6)}/>
                </div>
            </div>
        </div>
        {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></>}
    </section> );
}

export default Cafe;