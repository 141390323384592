import './BackBtn.css'
import backarrow from '../../assets/design/back.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'usehooks-ts';
import deskBacArrow from '../../assets/design/Arrow 13.png'

function BackBtn(props) {
    const mobile = useMediaQuery('(max-width: 480px)');
    const navigate = useNavigate()
    const { t } = useTranslation();

    const handleBackBtn = () =>{
        navigate(props.destination)
    }

    if(mobile){
        return ( 
            <div className="back-btn-container" onClick={handleBackBtn}>
                <img src={backarrow} alt="" />
                <span>{t(props.title)}</span>
            </div>
        );
    }
    else{
        return ( 
            <div className="back-btn-container">
                {props.desktopRoute?.map((path, index)=>(
                   index + 1 === props.desktopRoute.length?
                    <span key={index} className='back-btn-route-span back-btn-route-active'> {t(path)} </span>
                    :
                    <div key={index} style={{display:'flex', alignItems:'center'}}>
                        <span className='back-btn-route-span' onClick={()=>navigate(`/${path}`)}> {t(path)} </span>
                        <img src={deskBacArrow} alt="" style={{paddingInline:25, height:15, marginTop:2.5}}/>
                    </div>
                ))}
            </div>
        );
    }

}

export default BackBtn;