import './LoftInGeorgia.css'
import loftGallery1 from '../../../../assets/private/Лофт в грузии/1.jpg'
import loftGallery2 from '../../../../assets/private/Лофт в грузии/2.jpg'
import loftGallery3 from '../../../../assets/private/Лофт в грузии/3.jpg'
import loftGallery4 from '../../../../assets/private/Лофт в грузии/4.jpg'
import loftGallery5 from '../../../../assets/private/Лофт в грузии/5.jpg'
import loftGallery6 from '../../../../assets/private/Лофт в грузии/6.jpg'
import loftGallery7 from '../../../../assets/private/Лофт в грузии/7.jpg'
import loftGallery8 from '../../../../assets/private/Лофт в грузии/8.jpg'
import loftGallery9 from '../../../../assets/private/Лофт в грузии/9.jpg'
import loftGallery10 from '../../../../assets/private/Лофт в грузии/10.jpg'

import loftGallery1small from '../../../../assets/private/Лофт в грузии/1s.jpg'
import loftGallery2small from '../../../../assets/private/Лофт в грузии/2s.jpg'
import loftGallery3small from '../../../../assets/private/Лофт в грузии/3s.jpg'
import loftGallery4small from '../../../../assets/private/Лофт в грузии/4s.jpg'
import loftGallery5small from '../../../../assets/private/Лофт в грузии/5s.jpg'
import loftGallery6small from '../../../../assets/private/Лофт в грузии/6s.jpg'
import loftGallery7small from '../../../../assets/private/Лофт в грузии/7s.jpg'
import loftGallery8small from '../../../../assets/private/Лофт в грузии/8s.jpg'
import loftGallery9small from '../../../../assets/private/Лофт в грузии/9s.jpg'
import loftGallery10small from '../../../../assets/private/Лофт в грузии/10s.jpg'

import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LazyImage from '../../../../components/LazyImage/LazyImage'
import useScrollTop from '../../../../hooks/useScrollTop'
import { useMediaQuery } from 'usehooks-ts'
import BackBtn from '../../../../components/BackBtn/BackBtn'

function LoftInGeorgia() {
    useScrollTop()
    const mobile = useMediaQuery('(max-width: 480px)')
    const { t } = useTranslation();
    const images = [loftGallery1, loftGallery2, loftGallery3, loftGallery4, loftGallery5, loftGallery6, loftGallery7, loftGallery8, loftGallery9, loftGallery10]
    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleLoftImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }
    
    const closeSlider = () => {
        setSliderVisible(false)
    }

    return ( 
        <section id='Loft'>
            <BackBtn title={'private'} desktopRoute={['projects','private','Georgian character']} destination={'/private'}/>
            <div className="project-row project-gap-50">
                <div className="row-left">
                    <div className="project-title-container">
                        <span className="project-title">{t("Georgian character")}</span>
                        <span className="project-city">{t("Tbilisi")}</span>
                        <span className="project-year"> 2022 </span>
                    </div>
                    <p className="project-description-text" style={{width:'97%'}}>
                       {t("georgia-description-text")}
                       <br/>
                       <br/>
                       {t("georgia-description-text-extra")}
                    </p>
                </div>
                <div className="row-right">
                <LazyImage 
                        image={loftGallery1} 
                        placeholder={loftGallery1small} 
                        class={'project-img'} 
                        aspect={1.6} 
                        callback={() => handleLoftImageClick(0)}
                    />
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile?{marginTop:10,gap:0}:{marginTop:50}}>
                <div className="row-left">
                    <LazyImage 
                            image={loftGallery2} 
                            placeholder={loftGallery2small} 
                            class={'project-img'} 
                            aspect={1.6} 
                            callback={() => handleLoftImageClick(1)}
                    />
                </div>
                <div className="row-right">
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile?{marginTop:10}:{marginTop:50}}>
                <div className="row-left">
                    <LazyImage 
                            image={loftGallery3} 
                            placeholder={loftGallery3small} 
                            class={'project-img'} 
                            aspect={1.6} 
                            callback={() => handleLoftImageClick(2)}
                    />
                </div>
                <div className="row-right">
                    <p className="project-description-text" style={{marginTop:0, marginBottom:50}}>
                       {t("georgia-description-text-2")}
                       <br/>
                       <br/>
                       {t("georgia-description-text-2-extra")}
                    </p>
                </div>
            </div>
            <div className="project-row" style={mobile?{marginTop:0}:{marginTop:0}}>
                <div className="row-left">
                </div>
                <div className="row-right">
                    <div className="project-double" style={mobile? {marginTop:0, flexDirection:'row'}:{marginTop:'-20%'}}>
                            <LazyImage 
                                image={loftGallery4} 
                                placeholder={loftGallery4small} 
                                class={'project-img'} 
                                aspect={0.83} 
                                callback={() => handleLoftImageClick(3)}
                            />
                            <LazyImage 
                                image={loftGallery5} 
                                placeholder={loftGallery5small} 
                                class={'project-img'} 
                                aspect={0.83} 
                                callback={() => handleLoftImageClick(4)}
                            />
                        </div>
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile?{marginTop:50, gap:10}:{marginTop:100}}>
                <div className="row-left">
                    <LazyImage 
                        image={loftGallery6} 
                        placeholder={loftGallery6small} 
                        class={'project-img'} 
                        aspect={1.6} 
                        callback={() => handleLoftImageClick(5)}
                    />
                </div>
                <div className="row-right">
                    <div className="project-single" style={mobile?{marginTop:0}:{marginTop:'40%'}}>
                        <LazyImage 
                            image={loftGallery7} 
                            placeholder={loftGallery7small} 
                            class={'project-img'} 
                            aspect={0.83} 
                            callback={() => handleLoftImageClick(6)}
                        />
                    </div>
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile?{marginTop:0, gap:10}:{marginTop:50}}>
                <div className="row-left">
                </div>
                <div className="row-right">
                    <div className="project-single" style={{marginLeft:'auto'}}>
                        <LazyImage 
                            image={loftGallery8} 
                            placeholder={loftGallery8small} 
                            class={'project-img'} 
                            aspect={0.83} 
                            callback={() => handleLoftImageClick(7)}
                        />
                    </div>
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile? {marginTop:50, flexDirection:'column-reverse', gap:10}:{marginTop:50}}>
                <div className="row-left">
                    <LazyImage 
                        image={loftGallery10} 
                        placeholder={loftGallery10small} 
                        class={'project-img'} 
                        aspect={1.6} 
                        callback={() => handleLoftImageClick(9)}
                    />
                </div>
                <div className="row-right">
                    <div className="project-single" style={mobile?{marginLeft:0}:{marginLeft:'auto'}}>
                        <LazyImage 
                            image={loftGallery9} 
                            placeholder={loftGallery9small} 
                            class={'project-img'} 
                            aspect={0.83} 
                            callback={() => handleLoftImageClick(8)}
                        />
                    </div>
                </div>
            </div>
            {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></> }
        </section>
     );
}

export default LoftInGeorgia;