import './Public.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import HighTechCover from '../../../assets/public/Офис Екатеринбург/ОБЛОЖКА.jpg'
import CafeCover from '../../../assets/public/Кафе/1.webp'
import useScrollTop from '../../../hooks/useScrollTop';
import BackBtn from '../../../components/BackBtn/BackBtn';

function Public() {
    useScrollTop()
    const { t } = useTranslation();
    return ( 
        <section id='Public'>
            <BackBtn title={'projects'} desktopRoute={['projects','public']} destination={'/projects'}/>
            <div className="public-block">
                <div className="public-content">
                    <Link to='/hightech' className='high-tech-a'>
                        <div className="high-tech">
                            <span className='public-title'>{t("High-tech office")}</span>
                            <img src={HighTechCover} alt="" loading='lazy'/>
                        </div>
                    </Link>
                    <Link to='/coffee-shop' className='high-tech-a'>
                        <div className="high-tech">
                            <span className='public-title'>{t("Vintage Coffee Shop")}</span>
                            <img src={CafeCover} alt="" loading='lazy'/>
                        </div>
                    </Link>
                </div>
            </div>
        </section> 
    );
}

export default Public;