import logo from '../../assets/contacts/Group 35042.svg'
import contact from '../../assets/contacts/contact-arrow.svg'
import { useTranslation } from 'react-i18next';
import './Contacts.css'
import { initPopup }from '../../components/Popup/Popup';
import GalleryBottom from '../../components/GalleryBottom/GalleryBottom';

import { vkLink, waLink, inLink, tgLink, phoneRef, phoneLabel, gmail } from '../../App';
import Wave from '../../components/Wave/Wave';

function Contacts(){
    const { t } = useTranslation();

    return(
        <section id="Contacts">
            <div className="contacts-wrapper">
                <div className="contacts">
                    <div className="contacts-top">
                        <div className="top-logo">
                            <img draggable={false} src={logo} alt="" />
                        </div>
                        <div className="top-data">
                            <a className='header-nav-a' href={`tel:${phoneRef}`}>{phoneLabel}</a>
                            <a className='header-nav-a' href={`mailto:${gmail}`}>{gmail}</a>
                        </div>
                    </div>
                    <Wave/>
                    <div className="contacts-bottom">
                        <ul className='contacts-list-desc'>
                            <li>
                                <a className='header-nav-a' href={vkLink}>vk.com</a>   
                            </li>
                            <li>
                                <a className='header-nav-a' href={waLink}>whatsapp</a> 
                            </li>
                            <li>
                                <a className='header-nav-a' href={tgLink}>telegram</a> 
                            </li>
                            <li>
                                <a className='header-nav-a' href={inLink}>{t("contacts-inst")}</a> 
                            </li>
                        </ul>
                        <ul className='contacts-list-mobile'>
                            <li className="left-col">
                                <a className='header-nav-a' href={vkLink}>vk.com</a>  
                                <a className='header-nav-a' href={waLink}>whatsapp</a>  
                            </li>
                            <li className="right-col">
                                <a className='header-nav-a' href={tgLink}>telegram</a>
                                <a className='header-nav-a' href={inLink}>{t("contacts-inst")}</a> 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="popup-zone">
                    <button className="popup-btn" onClick={initPopup}>
                        <div className="popup-btn-text">
                            <span>{t("contact-us")}</span>
                            <div className="arrow-bottom">
                                <img src={contact} alt="" />
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <GalleryBottom/>
        </section>
    )
}

export default Contacts