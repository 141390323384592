import './HomeGallery.css'
import g1 from '../../../assets/gallery/1.webp'
import g2 from '../../../assets/gallery/2.webp'
import g3 from '../../../assets/gallery/3.webp'
import g4 from '../../../assets/gallery/4.webp'
import g5 from '../../../assets/gallery/ОБЛОЖКА.webp'
import g6 from '../../../assets/gallery/6.webp'

import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'

import AliceCarousel from 'react-alice-carousel'

function HomeGallery(props) {
    const [prevPos, setPrevPos] = useState(1);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width: 480px)');
    const publicLabel = useRef(null);
    const privateLine = useRef(null);
    const publicLine = useRef(null);
    const privateLabel = useRef(null);
    const [activeType, setActiveType] = useState('private') 
    
    const imagesPrivate = activeType === 'private' ? 
    [
        <div className="mobile-gallery-img" >
            <img src={g1} className='mobile-gallery-img' alt="" onClick={()=>navigate('/white-appartaments')}/>
            <span className='mobile-gallery-span'>{t("White Apartament")}</span>
        </div>,
        <div className="mobile-gallery-img">
            <img src={g2} className='mobile-gallery-img' alt="" onClick={()=>navigate('/fusion')}/>
            <span className='mobile-gallery-span'>{t("Fusion in Zamoskvorechye")}</span>
        </div>,
        <div className="mobile-gallery-img">
            <img src={g3} className='mobile-gallery-img' alt="" onClick={()=>navigate('/georgian-character')}/>
            <span className='mobile-gallery-span'>{t("Georgian character")}</span>
        </div>,
        <div className="mobile-gallery-img">
            <img src={g4} className='mobile-gallery-img' alt="" onClick={()=>navigate('/monochrome')}/>
            <span className='mobile-gallery-span'>{t("Monochrome minimalism")}</span>
        </div>

    ]:[
        <div className="mobile-gallery-img">
            <img src={g6} className='mobile-gallery-img' alt="" onClick={()=>navigate('/coffee-shop')}/>
            <span className='mobile-gallery-span'>{t("Vintage Coffee Shop")}</span>
        </div>,
        <div className="mobile-gallery-img">
            <img src={g5} className='mobile-gallery-img' alt="" onClick={()=>navigate('/hightech')}/>
            <span className='mobile-gallery-span'>{t("High-tech office")}</span>
        </div>
    ]

    const handleGalleryMouseOver = (pos) =>{
        const curr = document.getElementById(pos)
        const prev = document.getElementById(prevPos)

        prev.classList.remove('gallery-active')
        curr.classList.add('gallery-active')

        if(!curr.classList.contains('g-green'))
            switchIndicatorColor()

        setPrevPos(pos)
        
    }

    const switchIndicatorColor = () =>{
        const images = document.querySelectorAll('.gallery-item')

        images.forEach(el=>{
            if(el.classList.contains('g-green'))
                el.classList.remove('g-green')
            else
                el.classList.add('g-green')
        })
    }

    const handleLabelClick = (e) =>{
        var target = e.target
        var path = target.dataset.url
        navigate(path)
    }

    const handleMobileLabelClick = (type) =>{
        if(type === 'private'){
            privateLine.current.classList.add('line-active')
            publicLine.current.classList.remove('line-active')

            privateLabel.current.classList.add('label-active')
            publicLabel.current.classList.remove('label-active')

            setActiveType('private')
        }
        else{
            privateLine.current.classList.remove('line-active')
            publicLine.current.classList.add('line-active')

            privateLabel.current.classList.remove('label-active')
            publicLabel.current.classList.add('label-active') 

            setActiveType('public')
        }
    }

    if(!mobile){
        return ( 
            <div className={props.extraPadding? "home-gallery-wrapper home-g-e-p" :"home-gallery-wrapper"}>
                <div className="gallery-wrapper">
                    <div id='1' data-url='/white-appartaments' className="gallery-item g-private gallery-active g-first g-green"
                        style={{backgroundImage: `url(${g1})`}} 
                        onMouseOver={() => handleGalleryMouseOver('1')}
                        onClick={handleLabelClick}>
                            <span className='gallery-span'>{t("White Apartament")}</span>
                        <span data-url='/private' id='g-private-label' className='gallery-label'>{t('private')}</span>  
                    </div>
    
                    <div id='2' className="gallery-item g-private g-green" 
                        style={{backgroundImage: `url(${g2})`}} 
                        onMouseOver={() => handleGalleryMouseOver('2')}
                        onClick={()=> navigate('/fusion')}>
                        <span className='gallery-span'>{t("Fusion in Zamoskvorechye")}</span>
                    </div>
    
                    <div id='3' className="gallery-item g-private g-green" 
                        style={{backgroundImage: `url(${g3})`}} 
                        onMouseOver={() => handleGalleryMouseOver('3')}
                        onClick={()=> navigate('/georgian-character')}>
                        <span className='gallery-span'>{t("Georgian character")}</span>
                    </div>
    
                    <div id='4' className="gallery-item g-private g-last g-green" 
                        style={{backgroundImage: `url(${g4})`}} 
                        onMouseOver={() => handleGalleryMouseOver('4')}
                        onClick={()=> navigate('/monochrome')}>
                        <span className='gallery-span'>{t("Monochrome minimalism")}</span>        
                    </div>
    
                    <div id='5' data-url='/hightech' className="gallery-item g-public g-first" 
                        style={{backgroundImage: `url(${g5})`}} 
                        onMouseOver={() => handleGalleryMouseOver('5')}
                        onClick={handleLabelClick}>
                            <span className='gallery-span'>{t("High-tech office")}</span>     
                            <span data-url='/public' className='gallery-label'>{t('public')}</span>
                    </div>
    
                    <div id='6' className="gallery-item g-public g-last" 
                        style={{backgroundImage: `url(${g6})`}} 
                        onMouseOver={() => handleGalleryMouseOver('6')}
                        onClick={()=> navigate('/coffee-shop')}>
                            <span className='gallery-span'>{t("Vintage Coffee Shop")}</span>     
                        </div>
                </div>
            </div>
        );
    }
    else{
        return(
            <div className="home-gallery-mobile">
                <div className="lines-indicator">
                    <div ref={privateLine} id='line-private' className="line line-active"></div>
                    <div ref={publicLine} id='line-public' className="line"></div>
                </div>
                <div className="lines-labels">
                    <span ref={privateLabel} id='label-private' className='label-active' onClick={()=> handleMobileLabelClick('private')}>{t('private')}</span>
                    <span ref={publicLabel} id='label-public' onClick={()=> handleMobileLabelClick('public')}>{t('public')}</span>
                </div>
                <div className="mobile-carousel">
                    <AliceCarousel
                        infinite
                        mouseTracking 
                        disableDotsControls={true}
                        disableButtonsControls={true}
                        items={imagesPrivate}
                    />
                </div>
     
            </div>
        )
    }
}

export default HomeGallery;