import './HighTechOffice.css'

import HighTechGallery1 from '../../../../assets/public/Офис Екатеринбург/0.jpg'
import HighTechGallery2 from '../../../../assets/public/Офис Екатеринбург/1.jpg'
import HighTechGallery3 from '../../../../assets/public/Офис Екатеринбург/2.jpg'
import HighTechGallery4 from '../../../../assets/public/Офис Екатеринбург/3.jpg'
import HighTechGallery5 from '../../../../assets/public/Офис Екатеринбург/4.jpg'

import HighTechGallery1small from '../../../../assets/public/Офис Екатеринбург/0s.jpg'
import HighTechGallery2small from '../../../../assets/public/Офис Екатеринбург/1s.jpg'
import HighTechGallery3small from '../../../../assets/public/Офис Екатеринбург/2s.jpg'
import HighTechGallery4small from '../../../../assets/public/Офис Екатеринбург/3s.jpg'
import HighTechGallery5small from '../../../../assets/public/Офис Екатеринбург/4s.jpg'

import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useScrollTop from '../../../../hooks/useScrollTop'
import LazyImage from '../../../../components/LazyImage/LazyImage'
import { useMediaQuery } from 'usehooks-ts'
import BackBtn from '../../../../components/BackBtn/BackBtn'

function HighTechOffice() {
    const mobile = useMediaQuery('(max-width:480px)')
    useScrollTop()
    const { t } = useTranslation();
    const images = [HighTechGallery1,
                    HighTechGallery2,
                    HighTechGallery3,
                    HighTechGallery4,
                    HighTechGallery5]
    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleMonochromeImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }

    const closeSlider = () => {
        setSliderVisible(false)
    }

    return ( 
    <section id="Hightech" className='project-container'>
        <BackBtn title={'public'} desktopRoute={['projects','public','High-tech office']} destination={'/public'}/>
        <div className="project-row project-gap-50">
            <div className="row-left">
                <div className="project-title-container h-mb-1-title">
                    <span className="project-title">{t("High-tech office")}</span>
                    <span className="project-city">{t("Yekaterinburg")}</span>
                    <span className="project-year"> 2020 </span>
                </div>
                <p className="project-description-text">
                    {t("hightech-description")}
                </p>
            </div>
            <div className="row-right">
            <LazyImage 
                    image={HighTechGallery1} 
                    placeholder={HighTechGallery1small} 
                    class={'project-img'} 
                    aspect={1.6} 
                    callback={() => handleMonochromeImageClick(0)}
                />
            </div>
        </div>
        <div className="project-row project-gap-50" style={mobile ? {marginTop:50}:{marginTop:140}}>
            <div className="row-left">
                <div className="project-double">
                    <LazyImage
                        image={HighTechGallery2} 
                        placeholder={HighTechGallery2small} 
                        class={'project-img'}
                        offset_y={ mobile ? '0%':'-50%'} 
                        aspect={0.83} 
                        callback={() => handleMonochromeImageClick(1)}
                    />
                    <LazyImage 
                        image={HighTechGallery3} 
                        placeholder={HighTechGallery3small} 
                        class={'project-img'} 
                        aspect={0.83} 
                        callback={() => handleMonochromeImageClick(2)}
                    />
                </div>
            </div>
            <div className="row-right">
            </div>
        </div>
        <div className="project-row" style={mobile?{marginTop:0}:{marginTop:245}}>
            <div className="row-left">
                <LazyImage 
                    image={HighTechGallery4} 
                    placeholder={HighTechGallery4small} 
                    class={'project-img'} 
                    aspect={1.6} 
                    callback={() => handleMonochromeImageClick(3)}
                />
            </div>
            <div className="row-right">
                <div className="project-single" style={mobile?{display:'none'}:{marginLeft:'auto'}}>
                    <LazyImage 
                        image={HighTechGallery5} 
                        placeholder={HighTechGallery5small} 
                        class={'project-img'} 
                        offset_y={'-53%'} 
                        aspect={0.832} 
                        callback={() => handleMonochromeImageClick(4)}
                    />
                </div>
            </div>
        </div>

        {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></>}
    </section> );
}

export default HighTechOffice;