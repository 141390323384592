function termsEn() {
    return ( <>
        <p>Alexander Sharkov ('we' or 'us') is committed to ensuring that your privacy is protected. This Privacy Policy explains how we use the information we collect about you and how you can instruct us if you prefer to limit the use of that information. Your use of this website is strictly in accordance with the Terms of Use which are displayed on the website and should be read before the rest of this website is used.</p>

        <h2 className="terms-en-h2">The information we collect and how we use it</h2>
        <p>When you provide us with your details we need to know your name, postal address, and e-mail address. We gather this information to allow us to process your request. The relevant information is then used by us to process your request and to communicate with you in general. This information will not be used by us for marketing purposes nor transferred to a third party without your prior consent. We will always aim to act in accordance with current legislation and to meet current internet best practices. Biopolax may also use aggregate information and statistics for the purposes of monitoring website usage in order to help us develop the website and our services. This statistical information will not be used to identify any individual.</p>
        
        <h2 className="terms-en-h2">Sensitive data</h2>
        <p>We do not seek to collect sensitive personal data (such as data revealing political opinions, religious beliefs, health, or other matters) through this site. If We do seek to collect such data, your prior consent hereto will be asked. Please note that by providing to us with unsolicited sensitive personal data you consent to our using those data as described above.</p>

        <h2 className="terms-en-h2">Updating your details</h2>
        <p>If any of the information that you have provided to us changes, for example, if you change your e-mail address, or name, or if you wish to cancel your request, please let us know by sending an e-mail to: “our e-mail address”</p>

        <h2 className="terms-en-h2">Your consent</h2>
        <p>By submitting your information you consent to the use of that information as set out in this policy. If We change our Privacy Policy we will post the changes on this page, and may place notices on other pages of the website, so that you may be aware of the information we collect and how we use it at all times. Continued use of the service will signify that you agree to any such changes.</p>
        <p>Should you make a request to us that requires your information to be sent to countries outside the European Economic Area the information you provide may be transferred to countries outside the European Economic Area that do not have similar protections in place regarding your data and its use as set out in this Policy.</p>


        <h2 className="terms-en-h2">Third party sites</h2>
        <p>Please note that this Privacy Policy does not extend to any third-party sites linked to this website.</p>
    </> );
}   

export default termsEn;