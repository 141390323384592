import g1 from '../../assets/about/gallery/1.webp'
import g2 from '../../assets/about/gallery/2.webp'
import g3 from '../../assets/about/gallery/3.webp'
import g4 from '../../assets/about/gallery/4.webp'
import g5 from '../../assets/about/gallery/5.webp'
import g6 from '../../assets/about/gallery/6.webp'

import './GalleryBottom.css'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts'
import { useEffect, useRef, useState } from 'react'

function GalleryBottom() {
    const [imgWidth, setImgWidth] = useState(336)
    const mobile = useMediaQuery('(max-width: 480px)')
    const navigate = useNavigate()
    const wrapper = useRef(null)
    const [boundaries, setBoundaries] = useState(null)
    const [imgOffset, setImgOffset] = useState(null)
    const [images, setImages] = useState(null)
    const [prevActiveIndex, setPrevActiveIndex] = useState(0)
    

    //mobile
    useEffect(()=>{
        if(mobile){
            const imgs = document.querySelectorAll('.b-g-m-img')
            imgs[0].addEventListener('load',() => {
                setImgWidth(imgs[0].width)
                setImages(imgs)
                var windWidth = window.screen.width
                var off = (windWidth - imgs[0].width)
                setImgOffset(off)
            })
        }

    },[mobile])

    const handleGalleryScroll = () =>{
        var sl = wrapper.current.scrollLeft
        if(boundaries === null){
            initBoundaries(6, sl)  //Количество фотографий в слайдере
        }
    
        boundaries?.forEach((key, value) => {
            if(sl >= value[0] && sl < value[1]){
                if(key !== prevActiveIndex)
                    setActiveGalleryImage(key)
            }
        })
    }

    const setActiveGalleryImage = (index) =>{
        images[index].classList.add('gal-active')
        images[prevActiveIndex].classList.remove('gal-active')
        setPrevActiveIndex(index)
    }

    //Use initialScrollLeft to use offset on some resultions ( generally should be 0 )
    const initBoundaries = (count, initialScrollLeft) =>{
        const tempB = new Map()
        var imageWidth = imgWidth
        var overflowOffset = 20

        for (let i = 0; i < count; i++) {
            if(i === 0){
                var firstEl = [0, imageWidth - imgOffset] 
                tempB.set(firstEl, 0)
            }
            else if( i === count-1 ){
                var offset = (i - 1) * overflowOffset
                var lastEl = [i * imageWidth - offset -  imgOffset, count * imageWidth - offset - imgOffset - initialScrollLeft]
                tempB.set(lastEl, count - 1)
            }
            else{
                var innerEl = [i * imageWidth - ((i - 1) * overflowOffset) - imgOffset - initialScrollLeft, (i + 1) * imageWidth - (i * overflowOffset) - imgOffset - initialScrollLeft]
                tempB.set(innerEl, i)
            }
        }
        setBoundaries(tempB)
    } 



    //desktop
    const handleMouseEnter = () =>{
        const g = document.querySelector('.about-bottom-gallery')
        g.classList.add('desaturate')
    }
    const handleMouseLeave = () =>{
        const g = document.querySelector('.about-bottom-gallery')
        g.classList.remove('desaturate')
    } 

    if(!mobile){
        return ( 
            <div className="about-bottom-gallery" 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img title='White Appartaments' src={g1} alt="" onClick={()=>navigate('/white-appartaments')}/>
                <img title='Fusion in Zamoskvorechye' src={g2} alt="" onClick={()=>navigate('/fusion')}/>
                <img title='Georgian character' src={g3} alt="" onClick={()=>navigate('/georgian-character')}/>
                <img title='Monochrome minimalism' src={g4} alt="" onClick={()=>navigate('/monochrome')}/>
                <img title='High-tech office' src={g5} alt="" onClick={()=>navigate('/hightech')}/>
                <img title='Vintage Coffee Shop' src={g6} alt="" onClick={()=>navigate('/coffee-shop')}/>
            </div>
        );
    }
    else{
        return(
            <div className="about-bottom-gallery-mobile">
                <div ref={wrapper} className="gall-wrapper"
                onScroll={handleGalleryScroll}>
                    <img draggable='false' className='b-g-m-img gal-active' title='White Appartaments' src={g1} alt="" onClick={()=>navigate('/white-appartaments')}/>
                    <img draggable='false'  className='b-g-m-img' title='Fusion in Zamoskvorechye' src={g2} alt="" onClick={()=>navigate('/fusion')}/>
                    <img draggable='false'  className='b-g-m-img' title='Georgian character' src={g3} alt="" onClick={()=>navigate('/georgian-character')}/>
                    <img draggable='false'  className='b-g-m-img' title='Monochrome minimalism' src={g4} alt="" onClick={()=>navigate('/monochrome')}/>
                    <img draggable='false'  className='b-g-m-img' title='High-tech office' src={g5} alt="" onClick={()=>navigate('/hightech')}/>
                    <img draggable='false'  className='b-g-m-img' title='Vintage Coffee Shop' src={g6} alt="" onClick={()=>navigate('/coffee-shop')}/>
                </div>
            </div>
        )
    } 
}

export default GalleryBottom;