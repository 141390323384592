import './Popup.css'
import { useTranslation } from 'react-i18next';
import contactIcon from '../../assets/design/Arrow 47.svg'
import emailjs from '@emailjs/browser';
import { emailJSpublicKey, emailJSserviceID, emailJStemplateID, mailTo } from '../../EmailConfig'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { useMask } from '@react-input/mask';


function Popup() {
    const { t } = useTranslation();
    const [sender, setSender] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const [phone, setPhone] = useState(undefined)
    const [mail, setMail] = useState(undefined)
    const [isPending, setIsPending] = useState(false)
    const [validName, setValidName] = useState(true)
    const [validPhone, setValidPhone] = useState(true)
    const phoneInput = useMask({ mask: '+ _ (___) ___ - __ - __', replacement: { _: /\d/ } });

    const handlePopupBgClick = (e) =>{
        if(e.target.className === 'popup-wrapper popup-show'){
            const wrapper = document.querySelector('.popup-wrapper')
            wrapper.classList.remove('popup-show')
        }
    }

    const handleFormSend = (e) =>{
        e.preventDefault()

        if(!checkValidFields()){ toast.error(t("form-error")); return }

        setIsPending(true)
        const messageParams = {
            subject:'Shareinteriors',
            sender:sender,
            message: message,
            to: mailTo,
            // reply_to: mail? mail : '',
            contact_phone: phone,
            contact_email: mail? mail : ''
        }
        sendMail(messageParams)
    }
 
    const checkValidFields = () => {
        let valid = true
        if(!sender){
            setValidName(false)
            valid = false
        }
        else setValidName(true) 
        
        if(!phone){
            setValidPhone(false)
            valid = false
        }
        else setValidPhone(true)

        return valid
    }

    const sendMail = (messageParams) => {
        emailjs.send(emailJSserviceID, emailJStemplateID, messageParams, {
            publicKey: emailJSpublicKey,
        })
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            toast.success(t("form-success"))
            closeForm()
            setIsPending(false)
        })
        .catch((err) => {
            console.log('FAILED...', err);
            toast.error(t("server-error"))
            setIsPending(false)
        });
    }
    
    const closeForm = () =>{
        const wrapper = document.querySelector('.popup-wrapper')
        wrapper.classList.remove('popup-show')
    }

    return ( 
        <div className="popup-wrapper" onClick={handlePopupBgClick}>
            <form className="popup-form" onSubmit={handleFormSend}>
                <div className="popup-inner">
                    <label htmlFor="name">
                        {!validName? t("popup-name")+'*' : t("popup-name")}
                    </label>
                    <input 
                        name='name'
                        onChange={e => setSender(e.target.value)} 
                        placeholder={t("form-field-name")}
                        type="text" />

                    <label htmlFor="tel">
                        {!validPhone? t("popup-tel")+'*' : t("popup-tel")}
                    </label>
                    <input
                        ref={phoneInput}
                        name='tel' 
                        onChange={e => setPhone(e.target.value)}
                        placeholder='+ X (XXX) XXX - XX - XX'
                        type="tel" />

                    <label htmlFor="email">
                        e-mail
                    </label>
                    <input 
                        name='email' 
                        onChange={e => setMail(e.target.value)}
                        placeholder={t("form-field-email")}
                        type="email" />

                    <label htmlFor="comm">
                        {t("popup-comm")}
                    </label>
                    <input 
                        name='comm' 
                        onChange={e => setMessage(e.target.value)}
                        placeholder={t("form-field-comment")}
                        type="text" />

                    <button type='submit' disabled={isPending}>
                        <span>{isPending?t("popup-sending"):t("popup-send")}</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export const initPopup = (e) =>{
    const wrapper = document.querySelector('.popup-wrapper')
    const form = wrapper.childNodes[0]
    let x = e.clientX
    let y = e.clientY
    
    wrapper.classList.add('popup-show')
    form.style.left = `${x}px`
    form.style.top = `${y}px`
}


export const PopupInitBtn = () =>{
    const { t } = useTranslation();

    return(
        <NavLink onClick={initPopup} className={'popup-initer'}>
            <span>{t("contact-us")}</span>
            <div className='contact-icon-wrapper'>
                <img src={contactIcon} alt="" />
            </div>
        </NavLink>
    )
}

export default Popup;