import './WhiteAppartaments.css'
import whiteGallery1 from '../../../../assets/private/Белые аппартаменты/1.webp'
import whiteGallery2 from '../../../../assets/private/Белые аппартаменты/2.webp'
import whiteGallery3 from '../../../../assets/private/Белые аппартаменты/3.webp'
import whiteGallery6 from '../../../../assets/private/Белые аппартаменты/6.webp'
import whiteGallery7 from '../../../../assets/private/Белые аппартаменты/7.webp'
import whiteGallery8 from '../../../../assets/private/Белые аппартаменты/8.webp'
import whiteGallery9 from '../../../../assets/private/Белые аппартаменты/9.webp'
import whiteGallery10 from '../../../../assets/private/Белые аппартаменты/10.webp'
import whiteGallery11 from '../../../../assets/private/Белые аппартаменты/11.webp'

import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useScrollTop from '../../../../hooks/useScrollTop'
import { useMediaQuery } from 'usehooks-ts'
import BackBtn from '../../../../components/BackBtn/BackBtn'

function WhiteAppartaments() {
    const mobile = useMediaQuery('(max-width: 480px)')
    useScrollTop()
    const { t } = useTranslation();
    const images = [whiteGallery1,
         whiteGallery2,
         whiteGallery3,
         whiteGallery6,
         whiteGallery7,
         whiteGallery8,
         whiteGallery9,
         whiteGallery10,
         whiteGallery11]
    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleWhiteImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }
    
    const closeSlider = () => {
            setSliderVisible(false)
    }

    return ( 
        <section id="White">
            <BackBtn title={'private'} desktopRoute={['projects','private','White appartament']} destination={'/private'}/>
            <div className="project-row w-mb-1">
                <div className="row-left">
                    <div className="project-title-container w-mb-1-title">
                        <span className="project-title" >{t("White Apartament")}</span>
                        <span className="project-city">{t("Yekaterinburg")}</span>
                        <span className="project-year"> 2023 </span>
                    </div>
                    <p className="project-description-text">
                        {t("white-decription-text")}
                    </p>
                </div>
                <div className="row-right">
                    <img className='project-img'  src={whiteGallery1} alt="" onClick={()=> 
                                handleWhiteImageClick(0)} loading='lazy'/>
                </div>
            </div>
            <div className="project-row wr-center project-gap-10">
                <div className="row-left">
                    <img className='project-img'  src={mobile? whiteGallery2 : whiteGallery3 } alt="" onClick={()=> 
                        mobile? handleWhiteImageClick(1) : handleWhiteImageClick(2) } loading='lazy'/>
                </div>
                <div className="row-right">
                    <div className="project-double w-mb-2-double">
                        <img className='project-img w-mb-2-double-offset'  src={whiteGallery9} alt="" onClick={()=> 
                                handleWhiteImageClick(6)} loading='lazy'/>
                        <img className='project-img'  src={whiteGallery8} alt="" onClick={()=> 
                                handleWhiteImageClick(5)} loading='lazy'/>
                    </div>
                </div>
            </div>
            <div className="project-row" style={mobile?{marginTop:50}:{marginTop:100}}>
                <div className="row-left">
                    {!mobile?
                      <img className='project-img'  src={whiteGallery2} alt="" onClick={()=> 
                        handleWhiteImageClick(1)} loading='lazy'/>:
                    <></>}
                </div>
                <div className="row-right">
                    <p className='white-extra-text-1'>
                        {t("white-extra-text-1")}
                    </p>
                </div>
            </div>
            <div className="project-row project-gap-50" style={mobile?{marginTop:20}:{marginTop:100}}>
                <div className="row-left">
                    <p className='white-extra-text-2'>
                        {t("white-extra-text-2")}
                    </p>
                </div>
                <div className="row-right">
                    <img className='project-img'  src={whiteGallery7} alt="" onClick={()=> 
                                handleWhiteImageClick(4)} loading='lazy'/>
                </div>
            </div>
            <div className="project-row w-mb-5" style={mobile? {marginTop:10, gap:10}:{marginTop:100}}>
                <div className="row-left">
                    <div className="project-double w-mb-5-double-2" style={mobile?{marginTop:50}:{marginTop:260}}>
                        <img className='project-img'  src={whiteGallery10} alt="" onClick={()=> 
                                handleWhiteImageClick(7)} loading='lazy'/>
                        <img className='project-img'  src={whiteGallery11} alt="" onClick={()=> 
                                handleWhiteImageClick(8)} loading='lazy'/>
                    </div>
                </div>
                <div className="row-right">
                    <img className='project-img' src={whiteGallery6} alt="" onClick={()=> 
                                handleWhiteImageClick(3)} loading='lazy'/>
                </div>
            </div>
            {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></> }
        </section> 
    );
}

export default WhiteAppartaments;