import './Footer.css'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/design/logo.svg'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import LanguageSwitcher from '../Header/LanguageSwitcher/LanguageSwitcher';
import {PopupInitBtn} from '../Popup/Popup';
import { vkLink, waLink, inLink, tgLink, phoneRef, phoneLabel, gmail } from '../../App';

function Footer() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width: 480px)')

    if(mobile){
        return(
            <footer className="mobile-footer">
                <div className="mobile-menu-content-top">
                    <div className="footer-logo" onClick={()=>navigate('/')}>
                        <img src={logo} alt="" />
                        <div className="logo-labels">
                            <span>interior</span>
                            <span>design</span>
                            <span>studio</span>
                        </div>
                    </div>
                    <ul className='mobile-menu-nav'>
                        <li>
                            <NavLink to='/' >{t("home")}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/about'>{t("about")}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/projects'>{t("projects")}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contacts'>{t("contacts")}</NavLink>
                        </li>
                        <li><LanguageSwitcher className='lng-switcher'/></li>
                    </ul>
                </div>
                <div className="mobile-menu-content-bottom">
                    <ul>
                        <li>
                            <a href={`tel:${phoneRef}`}>{phoneLabel}</a>
                        </li>
                        <li>
                            <a href={`mailto:${gmail}`}>{gmail}</a>
                        </li>
                    </ul>
                    <ul className='m-last-list'>
                        <li>
                            <a href={tgLink}>telegram</a>
                        </li>
                        <li>
                            <a href={inLink}>{t('contacts-inst')}</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-bottom">
                    <div className="contact-link-conatiner footer-contact-link">
                        <PopupInitBtn/>
                    </div>
                    <NavLink to='/terms'>{t("terms")}</NavLink>
                </div>
            </footer>
        )
    }

    return ( 
        <footer>
            <div className="footer-content">
                <nav className="footer-nav">
                    <ul>
                        <li>
                            <NavLink onClick={ window.scrollTo(0,0) } to='/' className='header-nav-a'>
                                {t("home")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={ window.scrollTo(0,0) } to='/about' className='header-nav-a'>
                                {t("about")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={ window.scrollTo(0,0) } to='/projects' className='header-nav-a'>
                                {t("projects")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={ window.scrollTo(0,0)} to='/contacts' className='header-nav-a'>
                                {t("contacts")}
                            </NavLink>
                        </li>
                    </ul>
                </nav>

                <ul>
                    <li>
                        <a href={vkLink} className='header-nav-a'>vk.com</a>
                    </li>
                    <li>
                        <a href={waLink} className='header-nav-a'>whatsapp</a>
                    </li>
                    <li>
                        <a href={tgLink} className='header-nav-a'>telegram</a>
                    </li>
                    <li>
                        <a href={inLink} className='header-nav-a'>{t("contacts-inst")}</a>
                    </li>
                </ul>

                <div className="footer-logo"  onClick={()=>navigate('/')}>
                    <img src={logo} alt="" style={{width:'100%'}}/>
                    <div className="logo-labels">
                        <span>interior</span>
                        <span>design</span>
                        <span>studio</span>
                    </div>
                </div>
            </div>
            <div className={i18n.language === 'ru'? "footer-bottom f-b-ru" :"footer-bottom f-b-en"}>
                <div className="contact-link-conatiner footer-contact-link">
                    <PopupInitBtn/>
                </div>
                <NavLink to='/terms'>{t("terms")}</NavLink>
                <div className="f-placeholder"></div>
            </div>

        </footer>
    );
}

export default Footer;