import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const useHomeTransition = (animationDelay) =>{
    const navigate = useNavigate()
    const location = useLocation();
    const [homePage, setHomePage] = useState(null)
    const [homeTitle, setHomeTitle] = useState(null)
    const [onHome, setOnHome] = useState(true)
    const delay = animationDelay

    useEffect(()=>{
        location.pathname === '/' ?  setOnHome(true) : setOnHome(false)
    },[location])

    useEffect(()=>{
        var home = document.querySelector('.home-content')
        var title = document.querySelector('.home-logo-container')

        setHomePage(home)
        setHomeTitle(title)
    },[])


    // const handleHome = (e) =>{
    //     setHomeCollapsed()
    // }

    const handleAbout = (e) =>{  
        e.preventDefault()
        if(!onHome){
            navigate('/about') 
            return 
        }

        setHomeExpanded()      
        setTimeout(() => {
            navigate('/about') 
        },delay)
    }
    const handleProjects = (e) =>{  
        e.preventDefault()

        if(!onHome){
            navigate('/projects') 
            return 
        }

        setHomeExpanded()   
        setTimeout(() => {
            navigate('/projects') 
        },delay)
    }
    const handleContacts = (e) =>{  
        e.preventDefault()

        if(!onHome){
            navigate('/contacts')
            return 
        }

        setHomeExpanded()
        setTimeout(() => {
            navigate('/contacts') 
        },delay)
    }

    const setHomeExpanded = () =>{
        homePage.classList.add('expanded')
        homeTitle.classList.add('title-home-expanded')
    }
    // const setHomeCollapsed = () =>{
    //     homePage.classList.remove('expanded')
    //     homeTitle.classList.remove('title-home-expanded')
    // }

    return { handleAbout, handleProjects, handleContacts, setHomeExpanded}
}

export default useHomeTransition