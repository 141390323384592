import { toggleBodyScrollable, enableBodyScroll} from '../../utils'
import { useTranslation, initReactI18next } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import logo from '../../assets/design/logo.svg'
import shar from '../../assets/design/mobile/Vector.svg'
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher'
import translationEN from './../../locales/en/translation.json'
import translationRU from './../../locales/ru/translation.json'
import i18n from "i18next"
import './Header.css'
import useHomeTransition from '../../hooks/useHomeTransition'
import { useLocation } from 'react-router-dom'
import { gmail, inLink, phoneLabel, phoneRef, tgLink } from '../../App'


const resources = {
    en: {
        translation: translationEN,
    },
    ru:{
        translation: translationRU
    }
}
i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
})

function Header(){
    const path = useLocation().pathname
    const navigate = useNavigate()

    const homePage = path === '/'
    const { t } = useTranslation();
    const { handleAbout, handleProjects, handleContacts } = useHomeTransition(500)

    const handleMobileNavClick = () =>{
        if(homePage){
            toggleBodyScrollable()
            handleHomePageBtnClcik()
        }
        else{
            toggleBodyScrollable()
            const headerMobileBtn = document.getElementById('mobile-menu-toggle')
            const mobileMenu = document.querySelector('.mobile-menu')

            headerMobileBtn.classList.toggle('header-mobile-btn-toggled')
            mobileMenu.classList.toggle('mobile-menu-toggled')
        }
    }

    const handleLogoClick = () =>{
        enableBodyScroll()
        navigate('/')
    }
    
    const handleHomePageBtnClcik = () => {
        const home = document.getElementById('Home')
        const header = document.querySelector('.header-el')
        const homeContent = document.querySelector('.home-content')
        const homeTitle = document.querySelector('.home-logo-container')
        const headerMobileBtn = document.getElementById('mobile-menu-toggle')
        const heroSubtitle = document.querySelector('.subtitle-home')

        homeContent.classList.toggle('expanded-mobile')
        homeTitle.classList.toggle('home-logo-hidden')
        headerMobileBtn.classList.toggle('header-mobile-btn-toggled')
        headerMobileBtn.classList.toggle('mobile-btn-left')
        heroSubtitle.classList.toggle('hero-subtitle-hidden')
        home.classList.toggle('home-mobile-expanded')
        header.classList.toggle('header-m-toggled')
    }

    return(
    <>
        <div id={'main-header'} className="header-container">
            <header className={homePage ? 'header-el' : 'header-el header-m-toggled'}>
                <button id={'mobile-menu-toggle' } onClick={handleMobileNavClick} className={homePage ? 'mobile-menu-toggle-btn' : 'mobile-menu-toggle-btn mobile-btn-left'}>
                    <div className="toggle-line fst"></div>
                    <div className="toggle-line md"></div>
                    <div className="toggle-line end"></div>
                </button>
                <nav className='header-nav'>
                    <NavLink className={'header-nav-a'} to={'/'}>{t("home")}</NavLink>
                    <NavLink className={'header-nav-a'} onClick={handleAbout} to={'/about'}>{t("about")}</NavLink>
                    <NavLink className={'header-nav-a'} onClick={handleProjects} to={'/projects'}>{t("projects")}</NavLink>
                    <NavLink className={'header-nav-a'} onClick={handleContacts} to={'/contacts'}>{t("contacts")}</NavLink>
                </nav>
                <LanguageSwitcher className='lng-switcher'/>
                <div className="header-logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                    <div className="logo-labels">
                        <span>interior</span>
                        <span>design</span>
                        <span>studio</span>
                    </div>
                </div>
            </header>
            <div className="mobile-menu">
                <div className="mobile-menu-content_outer">
                    <div className="mobile-menu-content-top">
                        <ul className='mobile-menu-nav'>
                            <li>
                                <NavLink to='/' onClick={handleMobileNavClick}>{t("home")}</NavLink>
                            </li>
                            <li>
                                <NavLink to='/about' onClick={handleMobileNavClick}>{t("about")}</NavLink>
                            </li>
                            <li>
                                <NavLink to='/projects' onClick={handleMobileNavClick}>{t("projects")}</NavLink>
                            </li>
                            <li>
                                <NavLink to='/contacts' onClick={handleMobileNavClick}>{t("contacts")}</NavLink>
                            </li>
                            <li><LanguageSwitcher className='lng-switcher'/></li>
                        </ul>
                    </div>
                    <div className="mobile-menu-content-bottom">
                        <ul>
                            <li>
                                <a href={tgLink}>telegram</a>
                            </li>
                            <li>
                                <a href={inLink}>{t('contacts-inst')}</a>
                            </li>
                            <li>
                                <a href={`tel:${phoneRef}`}>{phoneLabel}</a>
                            </li>
                            <li>
                                <a href={`mailto:${gmail}`}>{gmail}</a>
                            </li>
                        </ul>
                    </div>
                    <img src={shar} alt="" className="shar-menu-texture" />
                </div>
            </div>
        </div>
    </>
    )
}

export default Header