import React from 'react';
import AliceCarousel from 'react-alice-carousel';

import 'react-alice-carousel/lib/alice-carousel.css';
import './InfiniteImageSlider.css'


function InfiniteImageSlider(props){
    let content =  props.content;
    const startIndex = props.startIndex;
    const close = () => props.closeFunction();

    const handleDragStart = (e) => e.preventDefault();
    let lastPart = content.slice(0, startIndex);
    let firstPart = content.slice(startIndex);
    let resContent = firstPart.concat(lastPart);
    const items = [];
    
    
    resContent.forEach(path => {
        items.push(<img src={path} className='slider-image' onDragStart={handleDragStart} alt="slider-content" />)
    });

    const handleSliderClick = (e) =>{
        if(e.target.className === 'image-slider' || e.target.className === 'alice-carousel__stage-item __active __target'){
            close()
        }
    }
 

    return(
        <div className={'image-slider'} onClick={handleSliderClick}>
            {/* <button className="slider-close" onClick={close}>
                <img src={crossIcon} alt="slider-close-icon" />
            </button> */}
            <AliceCarousel infinite mouseTracking items={items} />
        </div>   
        )
    }


export default InfiniteImageSlider