import LanguageSwitcher from '../../../components/Header/LanguageSwitcher/LanguageSwitcher'
import mainImage from '../../../assets/design/main-new2.webp'
import shar from '../../../assets/design/mobile/Vector.svg'
import useScrollTop from '../../../hooks/useScrollTop'
import Header from '../../../components/Header/Header'
import logo from '../../../assets/design/logo.svg'
import {PopupInitBtn } from '../../../components/Popup/Popup'
import Sketch from '../../../Distortion'
import './Home.css'

import { toggleBodyScrollable } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'
import { NavLink } from 'react-router-dom'
import {  useRef, useState } from 'react'
import { useEffect } from 'react'
import { inLink, tgLink, phoneRef, phoneLabel, gmail } from '../../../App';

function Home(){
    useScrollTop()
    const subtitleRef = useRef(null)
    const [ loading, setLoading ] = useState(true)
    const { t } = useTranslation();
    const mobile = useMediaQuery('(max-width: 480px)')

    useEffect(() => {
        if(!mobile){
            new Sketch({
                dom: document.getElementById("homeCanvas")
            });
        }
        setTimeout(() => {
            setLoading(false)
        },1000)
    }, [mobile]);

    if(!mobile){
        return(
            <>
                <div className="loader" style={{opacity:loading?1:0}}>
                    <div className="loader-overflower"></div>
                    <img className='img-loader' src={logo} alt="" />
                </div>
                <section id="Home">
                    <div id="homeCanvas" data-grid="70" data-mouse="0.25" data-strength="0.05" data-relaxation='0.9'>
                        <img src={mainImage} alt=""/>
                    </div>
                    <div className="home-content">
                        <Header/>
                        <div className="contact-link-conatiner">
                            <PopupInitBtn/>
                        </div>
                        <h2 ref={subtitleRef} className='subtitle-home'>interior design studio</h2>
                    </div>
                    <div className="home-logo-container">
                        <span>Sha</span>
                        <span className='h-l-c-last'>r</span>
                        <img src={logo} alt="" />
                    </div>
                </section>
            </> 
        )
    }
    else{
        return(
            <>
                <div className="loader" style={{opacity:loading?1:0}}>
                    <div className="loader-overflower"></div>
                    <img className='img-loader' src={logo} alt="" />
                </div>
                <section id="Home">
                    <div className="home-content">
                        <Header/>
                        <h2 ref={subtitleRef} className='subtitle-home'>interior design studio</h2>
                        <div className="mobile-menu-content">
                            <div className="mobile-menu-content-top">
                                <ul className='mobile-menu-nav'>
                                    <li>
                                        <NavLink to='/'>{t("home")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/about' onClick={()=> toggleBodyScrollable()}>{t("about")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/projects' onClick={()=> toggleBodyScrollable()}>{t("projects")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/contacts' onClick={()=> toggleBodyScrollable()}>{t("contacts")}</NavLink>
                                    </li>
                                    <li><LanguageSwitcher className='lng-switcher'/></li>
                                </ul>
                            </div>
                            <div className="mobile-menu-content-bottom">
                                <ul>
                                    <li>
                                        <a href={tgLink}>telegram</a>
                                    </li>
                                    <li>
                                        <a href={inLink}>{t('contacts-inst')}</a>
                                    </li>
                                    <li>
                                        <a href={`tel:${phoneRef}`}>{phoneLabel}</a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${gmail}`}>{gmail}</a>
                                    </li>
                                </ul>
                            </div>
                            <img src={shar} alt="" className="shar-menu-texture" />
                        </div>
                    </div>
                    <div className="home-logo-container">
                            <span>Sha</span>
                            <span className='h-l-c-last'>r</span>
                            <img src={logo} alt="" />
                    </div>
                </section>
            </> 
        )
    }
}

export default Home;