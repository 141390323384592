import './ProjectsGallery.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import BackBtn from '../../../components/BackBtn/BackBtn';

import private1 from '../../../assets/gallery/1.webp'
import private2 from '../../../assets/gallery/2.webp'
import private3 from '../../../assets/gallery/3.webp'
import private4 from '../../../assets/gallery/4.webp'
import public1 from '../../../assets/gallery/ОБЛОЖКА.webp'
import public2 from '../../../assets/gallery/6.webp'

function ProjectsGallery() {
    const [prevPos, setPrevPos] = useState('p1')
    const { t } = useTranslation();
    const navigate = useNavigate()

    const handlePGalleryMouseOver = (pos) =>{
        const curr = document.getElementById(pos)
        const prev = document.getElementById(prevPos)

        prev.classList.remove('projects-gallery-active')
        curr.classList.add('projects-gallery-active')

        setPrevPos(pos)
    }

    const handlePGalleryMouseLeave = (pos) =>{
        const curr = document.getElementById(pos)
        curr.classList.remove('projects-gallery-active')
    }

    return ( 
        <div className="projects-gallery">
            <BackBtn title={'home'} destination={'/'}/>
            <div className="projects-gallery-wrapper">
                <div id='p1' className="projects-gallery-item"
                    onMouseOver={() => handlePGalleryMouseOver('p1')}
                    onMouseLeave={() => handlePGalleryMouseLeave('p1')}
                    onClick={()=> navigate('/private')}>
                        <span style={{zIndex:11}}>{t('private')}</span>
                        <img style={{animationDelay:'0', zIndex:4}} src={private1} 
                            alt="" className="inner-image private-animation mobile-cover"/>
                        <img style={{animationDelay:'2s', zIndex:3}} src={private2} 
                            alt="" className="inner-image private-animation "/>
                        <img style={{animationDelay:'4s', zIndex:2}} src={private3} 
                            alt="" className="inner-image private-animation "/>
                        <img style={{animationDelay:'6s', zIndex:1}} src={private4} 
                            alt="" className="inner-image private-animation "/>
                </div>
                <div id='p2' className="projects-gallery-item" 
                    onMouseOver={() => handlePGalleryMouseOver('p2')}
                    onMouseLeave={() => handlePGalleryMouseLeave('p2')}
                    onClick={()=> navigate('/public')}>
                        <span style={{zIndex:11}}>{t('public')}</span>
                        <img src={public1} style={{animationDelay:'0', zIndex:4}} 
                            alt="" className="inner-image public-animation mobile-cover" />
                        <img src={public2} style={{animationDelay:'2s', zIndex:3}} 
                            alt="" className="inner-image public-animation" />
                </div>
            </div>
        </div>
    );
}

export default ProjectsGallery;