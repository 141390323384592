import './Private.css'
import { useTranslation } from 'react-i18next'
import fusion from '../../../assets/private/fusion-new.webp'
import white from '../../../assets/private/1573372780.webp'
import loft from '../../../assets/private/loft.webp'
import mono from '../../../assets/private/monochrome.webp'

import PrivateCell from './PrivateCell/PrivateCell.jsx'
import useScrollTop from '../../../hooks/useScrollTop.jsx'
import BackBtn from '../../../components/BackBtn/BackBtn.jsx'

function Private(props){
    const { t } = useTranslation();
    useScrollTop()
    return(
        <section id="Private">
            <BackBtn title={'projects'} desktopRoute={['projects','private']} destination={'/projects'}/>
            <div className="private-container">
                <div className="private-table">
                    <PrivateCell 
                        class={'fusion'} 
                        image={fusion} 
                        title={t("Fusion in Zamoskvorechye")} 
                        wide={false} 
                        path={'/fusion'} />

                    <PrivateCell 
                        class={'white'} 
                        image={white} 
                        title={t("White Apartament")} 
                        wide={true}
                        path={'/white-appartaments'}/>

                    <PrivateCell 
                        class={'loft'} 
                        image={loft} 
                        title={t("Georgian character")} 
                        wide={true}
                        path={'/georgian-character'}/>

                    <PrivateCell 
                        class={'mono'} 
                        image={mono} 
                        title={t("Monochrome minimalism")} 
                        wide={false}
                        path={'/monochrome'}/>
                </div>
            </div>
        </section>
    )
}

export default Private