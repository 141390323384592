import './PrivateCell.css'
import { Link } from 'react-router-dom'

function PrivateCell(props){
    return(
        <Link to={props.path} className={props.wide? 'cell-image wide ' + props.class  : 'cell-image ' + props.class}>
            <span className='cell-title'>{props.title}</span>
            <img src={props.image} alt="" draggable='false' loading='lazy'/>
        </Link>
    )
}

export default PrivateCell