import './LazyImage.css'

function LazyImage(props) {
    return (
        <div className="blured-lazy" 
        style={{
            backgroundImage: `url(${props.placeholder})`,
            aspectRatio: props.aspect,
            transform:`translateY(${props.offset_y})`}}>
            <img src={props.image} className={props.class} alt="" onClick={()=> props.callback()} loading="lazy"/>
        </div>
     );
}

export default LazyImage;