import './TopAnchor.css'
import arrow from '../../assets/icons/arrow-top.svg'
import { useEffect } from 'react';

function TopAnchor() {
    useEffect(()=>{
        document.addEventListener('scroll', handlePageScroll)

        return () => document.removeEventListener('scroll', handlePageScroll)
    })

    const handlePageScroll = () =>{
        var anchor = document.querySelector('.top-anchor')

        if(window.scrollY < 500){
            if (anchor.classList.contains('visible-anchor'))
                anchor.classList.remove('visible-anchor')
            return
        }
        anchor.classList.add('visible-anchor')
    }

    const scrollToTop = () =>{
        window.scrollTo(0,0)
    }

    return ( 
        <div className="top-anchor" onClick={scrollToTop}>
            <img src={arrow} alt="" />
        </div>
    );
}

export default TopAnchor;