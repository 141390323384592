import './MonochromeMinimalism.css'
import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import InfiniteImageSlider from '../../../../components/InfiniteImageSlider/InfiniteImageSlider.jsx'
import LazyImage from '../../../../components/LazyImage/LazyImage.jsx'
import BackBtn from '../../../../components/BackBtn/BackBtn.jsx'
import useScrollTop from '../../../../hooks/useScrollTop.jsx'

import MonochromeGallery1 from '../../../../assets/private/Дом Подмосковье/1.jpg'
// import MonochromeGallery2 from '../../../../assets/private/Дом Подмосковье/2.jpg'
import MonochromeGallery3 from '../../../../assets/private/Дом Подмосковье/3.jpg'
import MonochromeGallery4 from '../../../../assets/private/Дом Подмосковье/4.jpg'
import MonochromeGallery5 from '../../../../assets/private/Дом Подмосковье/5.jpg'
import MonochromeGallery6 from '../../../../assets/private/Дом Подмосковье/6.png'
import MonochromeGallery7 from '../../../../assets/private/Дом Подмосковье/7.png'
import MonochromeGallery8 from '../../../../assets/private/Дом Подмосковье/8.jpg'
import MonochromeGallery9 from '../../../../assets/private/Дом Подмосковье/9.jpg'
import MonochromeGallery10 from '../../../../assets/private/Дом Подмосковье/10.jpg'
import MonochromeGallery11 from '../../../../assets/private/Дом Подмосковье/11.jpg'

import MonochromeGallery1small from '../../../../assets/private/Дом Подмосковье/1s.jpg'
// import MonochromeGallery2small from '../../../../assets/private/Дом Подмосковье/2s.jpg'
import MonochromeGallery3small from '../../../../assets/private/Дом Подмосковье/3s.jpg'
import MonochromeGallery4small from '../../../../assets/private/Дом Подмосковье/4s.jpg'
import MonochromeGallery5small from '../../../../assets/private/Дом Подмосковье/5s.jpg'
import MonochromeGallery6small from '../../../../assets/private/Дом Подмосковье/6s.png'
import MonochromeGallery7small from '../../../../assets/private/Дом Подмосковье/7s.png'
import MonochromeGallery8small from '../../../../assets/private/Дом Подмосковье/8s.jpg'
import MonochromeGallery9small from '../../../../assets/private/Дом Подмосковье/9s.jpg'
import MonochromeGallery10small from '../../../../assets/private/Дом Подмосковье/10s.jpg'
import MonochromeGallery11small from '../../../../assets/private/Дом Подмосковье/11s.jpg'


function MonochromeMinimalism() {
    useScrollTop()
    const mobile = useMediaQuery('(max-width: 480px)')
    const { t } = useTranslation();
    const images = [MonochromeGallery1,
                    MonochromeGallery3,
                    MonochromeGallery4,
                    MonochromeGallery5,
                    MonochromeGallery6,
                    MonochromeGallery7,
                    MonochromeGallery8,
                    MonochromeGallery9,
                    MonochromeGallery10,
                    MonochromeGallery11]
    const [sliderStartIndex, setSliderStartIndex] = useState(0)
    const [sliderVisible, setSliderVisible] = useState(false)

    const handleMonochromeImageClick = (index) => {
        setSliderStartIndex(index)
        setSliderVisible(true)
    }
    
    const closeSlider = () => {
        setSliderVisible(false)
    }

    return ( 
        <section className='project-container '>
            <BackBtn title={'private'} desktopRoute={['projects','private','Monochrome minimalism']} destination={'/private'}/>
            <div className="project-row project-gap-50">
                <div className="row-left">
                    <div className="project-title-container">
                        <span className='project-title'>{t("Monochrome minimalism")}</span>
                        <span className={'project-city'}>{t("Moscow")}</span>
                        <span className='project-year'>2021</span>
                    </div>
                    <p className="project-description-text">
                        {t("monochrome-description-1")}
                    </p>
                    <p className="project-description-text" style={{marginTop:20}}>
                        {t("monochrome-description-2")}
                    </p>
                </div>
                <div className="row-right">
                    <LazyImage 
                            image={MonochromeGallery1} 
                            placeholder={MonochromeGallery1small} 
                            class={'project-img'} 
                            aspect={1.77} 
                            callback={() => handleMonochromeImageClick(0)}
                    />
                </div>
            </div>
            <div className="project-row" style={mobile?{marginTop:10}:{marginTop:100}}>
                <div className="row-left">
                    <LazyImage 
                        image={MonochromeGallery3} 
                        placeholder={MonochromeGallery3small} 
                        class={'project-img'} 
                        aspect={1.77} 
                        callback={() => handleMonochromeImageClick(1)}
                    />
                </div>
                <div className="row-right mm-rr-1" style={mobile?{display:'none', zIndex:9}:{paddingTop:0,zIndex:9}}>
                    <div className="project-single" style={{marginLeft:'auto'}}>
                        <LazyImage 
                            image={MonochromeGallery6} 
                            placeholder={MonochromeGallery6small} 
                            class={'project-img'} 
                            aspect={0.832} 
                            callback={() => handleMonochromeImageClick(4)}
                        />
                    </div>
                </div>
            </div>
            <div className="project-row" style={mobile?{marginTop:10}:{marginTop:50}}>
                <div className="row-left">
                    <LazyImage 
                        image={MonochromeGallery4} 
                        placeholder={MonochromeGallery4small} 
                        class={'project-img'} 
                        aspect={1.77} 
                        callback={() => handleMonochromeImageClick(2)}
                    />
                </div>
                <div className="row-right mm-rr-2" style={mobile?{marginTop:10, zIndex:8}:{paddingTop:0, zIndex:8}}>
                    <div className="project-single m-mb-single" style={{marginLeft:'auto'}}>
                        <LazyImage 
                            image={MonochromeGallery7} 
                            placeholder={MonochromeGallery7small} 
                            class={'project-img'} 
                            aspect={0.832} 
                            callback={() => handleMonochromeImageClick(5)}
                        />
                    </div>
                </div>
            </div>
            <div className="project-row" style={mobile?{marginTop:10}:{marginTop:620}}>
                <div className="row-left">
                    <LazyImage 
                        image={MonochromeGallery5} 
                        placeholder={MonochromeGallery5small} 
                        class={'project-img'} 
                        aspect={1.77} 
                        callback={() => handleMonochromeImageClick(3)}
                    />
                </div>
                <div className="row-right mm-rr-3" >
                    <div className="project-single m-mb-single" style={mobile?{marginTop:10}:{marginLeft:'auto'}}>
                            <LazyImage 
                                image={MonochromeGallery8} 
                                placeholder={MonochromeGallery8small} 
                                class={'project-img'} 
                                aspect={0.832} 
                                callback={() => handleMonochromeImageClick(6)}
                            />
                    </div>
                </div>
            </div>
            <div className="project-row project-gap-10" style={mobile?{marginTop:10}:{marginTop:50}}>
                <div className="row-left">
                    <div className="project-single" style={mobile?{margin:0}:{marginLeft:'auto'}}>
                        <LazyImage
                            image={MonochromeGallery9} 
                            placeholder={MonochromeGallery9small} 
                            class={'project-img'} 
                            aspect={0.832} 
                            callback={() => handleMonochromeImageClick(7)}
                        />
                    </div>
                </div>
                <div className="row-right">
                    <div className="project-double m-6-d">
                        <LazyImage 
                            image={MonochromeGallery10} 
                            placeholder={MonochromeGallery10small} 
                            class={'project-img '} 
                            aspect={0.832} 
                            callback={() => handleMonochromeImageClick(8)}
                        />
                        <LazyImage 
                            image={MonochromeGallery11} 
                            placeholder={MonochromeGallery11small} 
                            class={'project-img'} 
                            aspect={0.832} 
                            callback={() => handleMonochromeImageClick(9)}
                        />
                    </div>
                </div>
            </div>
            {sliderVisible ? <InfiniteImageSlider content={images} startIndex={sliderStartIndex} closeFunction={closeSlider}/> : <></>}
        </section> 
    );
}

export default MonochromeMinimalism;