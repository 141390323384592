import { Route, Routes } from "react-router-dom";
import React, { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { enableBodyScroll } from './utils.jsx';



import Home from './pages/HomePage/Home/Home.jsx';
import HomeGallery from './pages/HomePage/HomeGallery/HomeGallery.jsx';
import About from './pages/About/About.jsx';
import Contacts from './pages/Contacts/Contacts.jsx';

import Private from './pages/Projects/PrivateNav/Private.jsx';
import LoftInGeorgia from './pages/Projects/PrivateProjects/LoftInGeorgia/LoftInGeorgia.jsx';
import FusionInZamoskvorechye from './pages/Projects/PrivateProjects/FusionInZamoskvorechye/FusionInZamoskvorechye.jsx';
import MonochromeMinimalism from './pages/Projects/PrivateProjects/MonochromeMinimalism/MonochromeMinimalism.jsx';
import WhiteAppartaments from './pages/Projects/PrivateProjects/WhiteAppartaments/WhiteAppartaments.jsx';

import Public from './pages/Projects/PublicNav/Public.jsx';
import HighTechOffice from './pages/Projects/PublicProjects/HighTechOffice/HighTechOffice.jsx'
import Cafe from './pages/Projects/PublicProjects/Cafe/Cafe.jsx';

import TermsPage from './pages/TermsPage/TermsPage.jsx';

import Footer from './components/Footer/Footer.jsx';
import Popup from './components/Popup/Popup.jsx';
import ProjectsGallery from './pages/Projects/ProjectsGallery/ProjectsGallery.jsx'

import PageLayout from './components/PageLayout/PageLayout.jsx';
import './Projects.css'

export const tgLink='https://t.me/sharinteriors'
export const vkLink='https://vk.com/sharinteriors'
export const waLink='https://wa.me/79777066700'
export const inLink='https://www.instagram.com/shar_interiors'
export const phoneRef='+79777066700'
export const phoneLabel='+7 977 706 6700'
export const gmail='sharinteriors@gmail.com'

export const sitename='Sharinterior'

function App() {
    const { pathname } = useLocation();
    
    useEffect(() => {
      window.scrollTo(0,0);
    }, [pathname]);

    useEffect(()=>{
        document.addEventListener("deviceready", handleBack, false);
        function handleBack(){
            const backKeyPressed = () =>{ enableBodyScroll() }
            document.addEventListener("backbutton", backKeyPressed, false);  
        }
    },[])

    return (
        <>
            <Routes>
                <Route path='/' element={
                    <Fragment>
                        <Home/>
                        <HomeGallery extraPadding={false}/>
                    </Fragment>
                }/>

                <Route element={<PageLayout anchor={false}/>}>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/projects' element={<ProjectsGallery/>}/>
                    <Route path='/contacts' element={<Contacts/>}/>
                    <Route path='/private' element={<Private/>}/>
                    <Route path='/public' element={<Public/>}/>
                </Route>

                <Route element={<PageLayout anchor={true}/>}>
                    <Route path='/fusion' element={<FusionInZamoskvorechye/>}/>
                    <Route path='/white-appartaments' element={<WhiteAppartaments/>}/>
                    <Route path='/georgian-character' element={<LoftInGeorgia/>}/>
                    <Route path='/monochrome' element={<MonochromeMinimalism/>}/>
                    <Route path='/hightech' element={<HighTechOffice/>}/>
                    <Route path='/coffee-shop' element={<Cafe/>}/>
                    <Route path='/terms' element={<TermsPage/>}/>
                </Route> 
            </Routes>
            <Footer/>
            <Toaster/>
            <Popup/>
        </>
    );
}

export default App;